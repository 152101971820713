export default defineNuxtRouteMiddleware(async () => {
  const { countriesOfCitizenship, countriesOfResidence } = useCountryStore();

  if (
    !countriesOfCitizenship.value?.length ||
    !countriesOfResidence.value?.length
  ) {
    const { getCountries } = useCountryStore();

    await getCountries();
  }
});
